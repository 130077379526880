import React from 'react';
import { Layout as MainLayout } from '../../components/Layout';
import { ReactEventContent, ReactEventTypeContent } from './ReactEventConstants';
import styles from './reactEventTypeSearch.module.scss';
import { Helmet } from 'react-helmet';
import { ReactEventType } from './ReactEventType';
const { highlight } = styles;

const ReactEventTypeSearch = ({
  pageContext,
}: {
  pageContext: {
    eventType: string;
  };
}) => {
  const { eventType } = pageContext;
  const findAttribute = (content: ReactEventContent) =>
    content?.attributes?.find((attr) => attr.toLowerCase() === eventType.toLowerCase());
  const content = ReactEventTypeContent.find((content) => findAttribute(content));
  const attribute = findAttribute(content);

  return (
    <MainLayout>
      <Helmet title={`TypeScript definition for ${attribute} React event`} />
      <div className="article overflow-hidden py-32">
        <div className="not-prose">
          <h1 className="text-3xl not-prose md:text-5xl font-extrabold tracking-lighter text-gray-200 text-center">
            What is the TypeScript definition for the <span className={highlight}>{attribute}</span>{' '}
            event in React?
          </h1>
        </div>
        <ReactEventType content={content} attribute={attribute} />
      </div>
    </MainLayout>
  );
};

export default ReactEventTypeSearch;
